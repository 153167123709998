import { AppManifestBuilder } from '@wix/app-manifest-builder';
import { LIGHTBOX_CONNECTED_PARAMS } from './constants';

export const toScopedPreset = (presets) => ({
  desktop: {
    layout: presets.desktop,
    style: presets.desktop,
  },
  mobile: {
    layout: presets.mobile,
    style: presets.mobile,
  },
});

export const getRole = (wixCodeId: string): string => {
  return wixCodeId.substring(1);
};

export const cleanAllPages = async (editorSDK, token, context, appDefId) => {
  try {
    const appPopups = (context.appPopups =
      await editorSDK.pages.popupPages.getApplicationPopups(''));
    console.log('🚀 ~ appPopups:', appPopups);
    const appPages = await editorSDK.pages.getApplicationPages('');
    console.log('🚀 ~ appPages:', appPages);
    for (const x of appPages) {
      console.log('page - ', x.id);
      await editorSDK.pages.remove('', {
        pageRef: { id: x.id!, type: 'DESKTOP' },
        shouldShowEditorRemovePanel: false,
      });
    }
    for (const x of appPopups) {
      console.log('popup - ', x.id);
      await editorSDK.pages.popupPages.open('', {
        popupRef: { id: x.id!, type: 'DESKTOP' },
      });
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await editorSDK.pages.remove('', {
        pageRef: { id: x.id!, type: 'DESKTOP' },
        shouldShowEditorRemovePanel: false,
      });
    }
    await editorSDK.routers.remove('token', {
      routerRef: {
        id: 'auth',
      },
    });
    console.log('router removed');
  } catch (e) {
    console.log('🚀 ~ file: appInstaller.ts:39 ~ .withStep ~ e:', e);
  }
};

export const closePopup = ({ $w, window }) => {
  setTimeout(() => {
    console.log('🚀 ~ file: utils.ts:226 ~ closePopup ~ $w:', $w);
    console.log(
      "🚀 ~ file: utils.ts:226 ~ $w ~ $w('@closeIcon'):",
      $w('closeIcon'),
    );
  }, 2000);
  $w('@closeIcon')?.onClick?.(() => {
    window?.lightbox?.close?.();
  });
};

export const setLightboxGfpp = (
  appManifestBuilder: AppManifestBuilder,
  id: string,
  displayName?: string,
) => {
  appManifestBuilder.configureController(
    `${LIGHTBOX_CONNECTED_PARAMS.CONTROLLER}_${id}`,
    (controllerBuilder) => {
      controllerBuilder.configureConnectedComponents(
        `${LIGHTBOX_CONNECTED_PARAMS.ROLE}_${id}`,
        (componentBuilder) => {
          displayName && componentBuilder.set({ displayName });
          componentBuilder.behavior();

          componentBuilder
            .gfpp()
            .set('mainAction2', { behavior: 'HIDE' })
            .set('settings', { behavior: 'HIDE' });
        },
      );
    },
  );
};
