import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const show = () => {
    $w('#box1').show();
  };
  const hide = () => {
    $w('#box1').hide();
  };
  return {
    pageReady: async () => {
      $w('#box1').hide();
      $widget.fireEvent('widgetLoaded', {});
      $w('#close').onClick(() => {
        $w('#box1').hide();
      });
    },
    exports: {
      show,
      hide,
    },
  };
});
