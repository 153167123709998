import {
  addWidgetOptions,
  SDKContext,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';

export type IScopedPreset = { desktop: string; mobile: string };
export const PRESETS = {
  ACCESS_RESTRICTED: {
    desktop: 'variants-l7dfro241',
    mobile: 'variants-l7svr70l',
  },
  EMAIL_CONFIRMATION: {
    desktop: 'variants-l9fcnw00',
    mobile: 'variants-l9fcnvzz',
  },
  ADMIN_APPROVAL: { desktop: 'variants-l9fcjf6v', mobile: 'variants-l9fcjf6w' },
  EXPIRED_TOKEN: { desktop: 'variants-l8a669tl', mobile: 'variants-l8a669tm' },
  RESET_PASSWORD: { desktop: 'variants-legzzrne', mobile: 'variants-lffdhd38' },
  FORGOT_PASSWORD: {
    desktop: 'variants-l9fke7so1',
    mobile: 'variants-lffek0jy',
  },
  ERROR: { desktop: 'variants-lcymr7xu1' },
};

export const ERROR_CODES = {
  PASSWORD_RESETED: '-19973',
  WRONG_AUTH_DETAILS: '-19976',
  ACCESS_DENID: '-19956',
  VALIDATION_ERROR: '-19988',
  WAITING_APPROVAL: '-19958',
  UNKNOWN_ACCOUNT: '-19999',
  WRONG_PASSWORD: '-17005',
  EXISTING_EMAIL_ACCOUNT: '-19995',
  CLIENT_AUTH_FORBIDDEN: '-19974',
  EMAIL_NOT_PROVIDED: '-18880',
  CAPTCHA_REQUIRED: '-19971',
  CAPTCHA_INVALID: '-19970',
  RESET_PASSWORD_TOKEN_EXPIRED: '-19972',
  NEW_RESET_PASSWORD_TOKEN_EXPIRED: 'EXPIRED_JWT_TOKEN',
  REQUEST_THROTTLED: '-19959',
  CODE_INVALID: 'EMAIL_VERIFICATION_REQUIRED',
  BAD_CODE: 'EMAIL_VERIFICATION_FAILED',
  SERVER_EXCEPTION: '-19901',
  AUTHENTICATION_FAILED: '-19976',
  UNIMPLEMENTED_FEATURE: 'UNIMPLEMENTED_FEATURE',
  IP_ADDRESS_BLACKLISTED: 'IP_ADDRESS_FILTERED',
};

export const EXPERIMENTS = {
  platformizedAuth: 'specs.ident.usePlatformizedSMAuth',
};

export const LIGHTBOX_IDS = {
  dispatchModal: 'dispatchModal',
  itemModal: 'itemModal',
  errorModal: 'errorModal',
};

export const LIGHTBOX_CONNECTED_PARAMS = {
  CONTROLLER: 'popupController',
  ROLE: 'popupContainer',
};

export const CUSTOM_ACTIONS_IDS = {
  MANAGE_PAGE_PERMISSIONS: 'managePagePermissions',
  MANAGE_MEMBER_ACCESS: 'manageMemberAccess',
  MANAGE_SIGNUP_SETTINGS: 'manageSignupSettings',
  EMAIL_CONFIRAMATION_DESIGN: 'emailConfirmationDesign',
  ACCESS_RESTRICTEDS_SHOW_HIDE_ELEMENTS: 'noPermissionsShowHideElements',
  RESET_PASSWORD_SHOW_HIDE_ELEMENTS: 'resetPasswordShowHideElements',
  FORGOT_PASSWORD_SHOW_HIDE_ELEMENTS: 'forgotPasswordShowHideElements',
  EMAIL_CONFIRMATION_SHOW_HIDE_ELEMENTS: 'emailConfirmationShowHideElements',
  ADMIN_APPROVAL_SHOW_HIDE_ELEMENTS: 'adminApprovalShowHideElements',
  EXPIRED_TOKEN_SHOW_HIDE_ELEMENTS: 'expiredTokenShowHideElements',
  ERROR_MESSAGE_SHOW_HIDE_ELEMENTS: 'errorMessageShowHideElements',
};

export const PANELS_IDS = {
  FORGOT_PASSWORD: 'q7m2m',
};

export const TPA_PAGES = {
  FORGOT_PASSWORD: 'forgotPasswordTPA',
  RESET_PASSWORD: 'resetPasswordTPA',
  ADMIN_APPROVAL: 'adminApprovalTPA',
  EMAIL_CONFIRMATION: 'emailConfirmationTPA',
  EXPIRED_TOKEN: 'expiredTokenTPA',
  ACCESS_RESTRICTED: 'accessRestrictedTPA',
};

export const COMPONENT_NAMES = {
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  ADMIN_APPROVAL: 'admin-approval',
  EMAIL_CONFIRMATION: 'email-confirmation',
  EXPIRED_TOKEN: 'expired-token',
  ACCESS_RESTRICTED: 'access-restricted',
};

export const COMPONENT_IDS = {
  FORGOT_PASSWORD: {
    CONTAINER: '#box1',
    MULTI_STATE_BOX: '#forgotPasswordState',
    CLOSE_BUTTON: '#closeBtn',
    EMAIL_STEP: {
      CONTAINER: '#reset',
      TITLE: '#resetPasswordTitle',
      SUB_TITLE: '#resetPasswordSubtitle',
      EMAIL_INPUT: '#emailInput',
      SUBMIT_BUTTON: '#submitButton',
      SERVER_ERROR: '#serverErrorMessage',
    },
    LINK_STEP: {
      CONTAINER: '#link',
      TITLE: '#resetPasswordLinkTitle',
      SUB_TITLE: '#resetPasswordLinkSubtitle',
      GOT_IT_BUTTON: '#resetPasswordLinkGotItButton',
      DIDNT_GET_EMAIL_INFO: '#didntGetEmailInfo',
      EMAIL: '#email',
    },
  },
  RESET_PASSWORD: {
    CONTAINER: '#box1',
    MULTI_STATE_BOX: '#createNewPasswordState',
    CREATE_PASSWORD_STEP: {
      TITLE: '#newPasswordTitle',
      PASSWORD_INPUT: '#passwordInput',
      CONFIRM_PASSWORD_INPUT: '#confirmPasswordInput',
      SUBMIT_BUTTON: '#resetPasswordCTA',
      CONTAINER: '#success',
      SERVER_ERROR: '#serverError',
    },
    SUCCESS_STEP: {
      TITLE: '#allSetTitle',
      SUB_TITLE: '#allSetSubtitle',
      CLOSE_BUTTON: '#resetPasswordSuccessCloseBtn',
      PRIMARY_BUTTON: '#loginBtn',
      SECONDARY_BUTTON: '#homeBtn',
      CONTAINER: '#createPassword',
    },
  },
  EXPIRED_TOKEN: {
    TITLE: '#expiredTokenTitle',
    SUB_TITLE: '#expiredTokenSubtitle',
    CLOSE_BUTTON: '#expiredTokenCloseBtn',
    SECONDARY_BUTTON: '#expiredTokenGoToSiteHomeButton',
    PRIMARY_BUTTON: '#expiredTokenLoginButton',
    CONTAINER: '#box1',
  },
  EMAIL_CONFIRMATION: {
    TITLE: '#emailConfirmationTitle',
    SUB_TITLE: '#emailConfirmationSubtitle',
    EMAIL: '#email',
    CLOSE_BUTTON: '#emailConfirmationCloseBtn',
    PRIMARY_BUTTON: '#submitButton',
    CONTAINER: '#box1',
    RESEND_TITLE: '#emailConfirmationResendTitle',
    RESEND_TEXT: '#emailConfirmationResendTextLink',
    OTP_INPUT: '#otpInput',
    RESEND_TIMER: '#resendTimer',
    CLIENT_ERROR: '#clientErrorMessage',
    SERVER_ERROR: '#serverErrorMessage',
  },
  ACCESS_RESTRICTEDS: {
    TITLE: '#noPermissionsTitle',
    SUB_TITLE: '#noPermissionsSubtitle',
    CLOSE_BUTTON: '#noPermissionsCloseBtn',
    BACK_BUTTON: '#backButton',
    SWITCH_ACCOUNT_BUTTON: '#switchAccount',
    CONTAINER: '#box1',
  },
  ADMIN_APPROVAL: {
    TITLE: '#adminApprovalTitle',
    SUB_TITLE: '#adminApprovalSubtitle',
    EMAIL: '#email',
    DESCRIPTION: '#adminApprovalDescription',
    CLOSE_BUTTON: '#closeBtn',
    PRIMARY_BUTTON: '#adminApprovalPrimaryButton',
    CONTAINER: '#box1',
  },
  ERROR_MESSAGE: {
    TEXT: '#errorText',
    ICON: '#errorIcon',
  },
};

export const STATES = {
  FORGOT_PASSWORD: {
    EMAIL: 'reset',
    LINK_SENT: 'link',
    ERROR: 'error',
  },
  RESET_PASSWORD: {
    CREATE_PASSWORD: 'createPassword',
    SUCCESS: 'success',
    ERROR: 'error',
  },
  EMAIL_CONFIRMATION: {
    DEFAULT: 'default',
    ERROR: 'error',
    CODE_RESEND_TIME: 'codeResendTime',
    WARNING: 'warning',
  },
};
