/* eslint-disable */
/* tslint:disable */
export function LocaleKeys<R extends string>(t: (...args: unknown[]) => R) {
  return {
    app: {
      settings: {
        tabs: {
          group: {
            authPages: () => t('app.settings.tabs.group.authPages'), /* Authentication Pages */
            permissions: () => t('app.settings.tabs.group.permissions'), /* Member Access */
            resetPassword: () => t('app.settings.tabs.group.resetPassword'), /* Reset Password */
            emailConfirmation: () => t('app.settings.tabs.group.emailConfirmation'), /* Email Confirmation */
          },
        },
        page: {
          action: {
            replace: () => t('app.settings.page.action.replace'), /* Replace with custom page */
          },
        },
        lightbox: {
          action: {
            replace: () => t('app.settings.lightbox.action.replace'), /* Replace with custom lightbox */
          },
        },
        replacer: {
          custom: () => t('app.settings.replacer.custom'), /* Custom */
        },
      },
      settingsTab: {
        settings: {
          title: () => t('app.settingsTab.settings.title'), /* Settings */
          pageInfo: () => t('app.settingsTab.settings.pageInfo'), /* Page Info */
        },
        layout: {
          title: () => t('app.settingsTab.layout.title'), /* Layout */
        },
      },
      widgets: {
        forgotPassword: {
          design: {
            tabs: {
              title: () => t('app.widgets.forgotPassword.design.tabs.title'), /* Title */
              subtitle: () => t('app.widgets.forgotPassword.design.tabs.subtitle'), /* Subtitle */
              errorMessages: () => t('app.widgets.forgotPassword.design.tabs.errorMessages'), /* Error messages */
              inputs: () => t('app.widgets.forgotPassword.design.tabs.inputs'), /* Input */
              mainButtons: () => t('app.widgets.forgotPassword.design.tabs.mainButtons'), /* Primary button */
              closeButtons: () => t('app.widgets.forgotPassword.design.tabs.closeButtons'), /* Close button */
              background: () => t('app.widgets.forgotPassword.design.tabs.background'), /* Background */
            },
            title: () => t('app.widgets.forgotPassword.design.title'), /* Forgot Password Design */
          },
          viewer: {
            emailStep: {
              title: () => t('app.widgets.forgotPassword.viewer.emailStep.title'), /* Reset password */
              subtitle: () => t('app.widgets.forgotPassword.viewer.emailStep.subtitle'), /* Enter your login email and we'll send you a link to reset your password. */
              email: () => t('app.widgets.forgotPassword.viewer.emailStep.email'), /* Login email */
              sendLink: () => t('app.widgets.forgotPassword.viewer.emailStep.sendLink'), /* Send Link */
            },
            linkSent: {
              title: () => t('app.widgets.forgotPassword.viewer.linkSent.title'), /* Reset password link was sent */
              description: (data: Record<'email', unknown>) => t('app.widgets.forgotPassword.viewer.linkSent.description', data), /* To finish resetting your password, use the link we sent to {email} */
              checkSpam: () => t('app.widgets.forgotPassword.viewer.linkSent.checkSpam'), /* Didn't get the email? Check your spam. */
              gotIt: () => t('app.widgets.forgotPassword.viewer.linkSent.gotIt'), /* Got it */
            },
          },
          states: {
            emailStep: () => t('app.widgets.forgotPassword.states.emailStep'), /* Enter email */
            linkSent: () => t('app.widgets.forgotPassword.states.linkSent'), /* Reset link sent */
            error: () => t('app.widgets.forgotPassword.states.error'), /* Error messages */
          },
          tooltip: () => t('app.widgets.forgotPassword.tooltip'), /* This page lets site members get a link to reset their password. */
          emailStep: {
            elements: {
              label: {
                title: () => t('app.widgets.forgotPassword.emailStep.elements.label.title'), /* Title */
                subtitle: () => t('app.widgets.forgotPassword.emailStep.elements.label.subtitle'), /* Subtitle */
                emailInput: () => t('app.widgets.forgotPassword.emailStep.elements.label.emailInput'), /* Email input */
                button: () => t('app.widgets.forgotPassword.emailStep.elements.label.button'), /* Send link button */
                serverErrorMessage: () => t('app.widgets.forgotPassword.emailStep.elements.label.serverErrorMessage'), /* Server error message */
              },
            },
          },
          linkSent: {
            elements: {
              label: {
                title: () => t('app.widgets.forgotPassword.linkSent.elements.label.title'), /* Title */
                subtitle: () => t('app.widgets.forgotPassword.linkSent.elements.label.subtitle'), /* Subtitle */
                button: () => t('app.widgets.forgotPassword.linkSent.elements.label.button'), /* Close lightbox button */
                checkSpam: () => t('app.widgets.forgotPassword.linkSent.elements.label.checkSpam'), /* Check spam text */
              },
            },
          },
        },
        resetPassword: {
          design: {
            tabs: {
              title: () => t('app.widgets.resetPassword.design.tabs.title'), /* Title */
              subtitle: () => t('app.widgets.resetPassword.design.tabs.subtitle'), /* Subtitle */
              errorMessages: () => t('app.widgets.resetPassword.design.tabs.errorMessages'), /* Error messages */
              inputs: () => t('app.widgets.resetPassword.design.tabs.inputs'), /* Input */
              mainButtons: () => t('app.widgets.resetPassword.design.tabs.mainButtons'), /* Primary button */
              linkButton: () => t('app.widgets.resetPassword.design.tabs.linkButton'), /* Link button */
              background: () => t('app.widgets.resetPassword.design.tabs.background'), /* Background */
            },
            title: () => t('app.widgets.resetPassword.design.title'), /* Create New Password Design */
          },
          viewer: {
            createPassword: {
              title: () => t('app.widgets.resetPassword.viewer.createPassword.title'), /* Create new password */
              enterPassword: () => t('app.widgets.resetPassword.viewer.createPassword.enterPassword'), /* Enter new password */
              confirmPassword: () => t('app.widgets.resetPassword.viewer.createPassword.confirmPassword'), /* Confirm new password */
              save: () => t('app.widgets.resetPassword.viewer.createPassword.save'), /* Create password */
            },
            success: {
              title: () => t('app.widgets.resetPassword.viewer.success.title'), /* You're all set */
              subtitle: () => t('app.widgets.resetPassword.viewer.success.subtitle'), /* Your password was changed. */
              login: () => t('app.widgets.resetPassword.viewer.success.login'), /* Log In */
              goHome: () => t('app.widgets.resetPassword.viewer.success.goHome'), /* Go to Site Home */
            },
          },
          states: {
            createPassword: () => t('app.widgets.resetPassword.states.createPassword'), /* Create password */
            success: () => t('app.widgets.resetPassword.states.success'), /* Success */
            error: () => t('app.widgets.resetPassword.states.error'), /* Error messages */
          },
          tooltip: () => t('app.widgets.resetPassword.tooltip'), /* This page lets site members create a new login password. */
          pageInfo: {
            title: () => t('app.widgets.resetPassword.pageInfo.title'), /* What’s a Create New Password page? */
            description: () => t('app.widgets.resetPassword.pageInfo.description'), /* <richText><p>It shows when site members need to create a new password to log in.</p><p>Go to the “Signup & Login Security” page to set up password requirements. <textButton>Learn More</textButton></p></richText> */
            link: () => t('app.widgets.resetPassword.pageInfo.link'), /* Signup & Login Security */
          },
          createPassword: {
            elements: {
              label: {
                title: () => t('app.widgets.resetPassword.createPassword.elements.label.title'), /* Title */
                passwordInput: () => t('app.widgets.resetPassword.createPassword.elements.label.passwordInput'), /* Password input */
                confirmPasswordInput: () => t('app.widgets.resetPassword.createPassword.elements.label.confirmPasswordInput'), /* Confirm password input */
                button: () => t('app.widgets.resetPassword.createPassword.elements.label.button'), /* Create password button */
                serverErrorMessage: () => t('app.widgets.resetPassword.createPassword.elements.label.serverErrorMessage'), /* Server error message */
              },
            },
          },
          success: {
            elements: {
              label: {
                title: () => t('app.widgets.resetPassword.success.elements.label.title'), /* Title */
                subtitle: () => t('app.widgets.resetPassword.success.elements.label.subtitle'), /* Subtitle */
                primaryButton: () => t('app.widgets.resetPassword.success.elements.label.primaryButton'), /* Primary button */
                secondaryButton: () => t('app.widgets.resetPassword.success.elements.label.secondaryButton'), /* Secondary button */
              },
            },
          },
        },
        emailConfirmation: {
          design: {
            tabs: {
              title: () => t('app.widgets.emailConfirmation.design.tabs.title'), /* Title */
              subtitle: () => t('app.widgets.emailConfirmation.design.tabs.subtitle'), /* Subtitle */
              errorMessages: () => t('app.widgets.emailConfirmation.design.tabs.errorMessages'), /* Error messages */
              inputs: () => t('app.widgets.emailConfirmation.design.tabs.inputs'), /* Input */
              mainButtons: () => t('app.widgets.emailConfirmation.design.tabs.mainButtons'), /* Primary button */
              spamMessage: () => t('app.widgets.emailConfirmation.design.tabs.spamMessage'), /* Check spam message */
              resendCode: () => t('app.widgets.emailConfirmation.design.tabs.resendCode'), /* Resend code button */
              background: () => t('app.widgets.emailConfirmation.design.tabs.background'), /* Background */
              closeButton: () => t('app.widgets.emailConfirmation.design.tabs.closeButton'), /* Close button */
            },
            title: () => t('app.widgets.emailConfirmation.design.title'), /* Email Confirmation Design */
          },
          viewer: {
            title: () => t('app.widgets.emailConfirmation.viewer.title'), /* Confirm your email */
            subtitle: (data: Record<'email', unknown>) => t('app.widgets.emailConfirmation.viewer.subtitle', data), /* Confirm your email by entering the 6-digit code sent to {email} */
            otpInput: () => t('app.widgets.emailConfirmation.viewer.otpInput'), /* Enter 6-digit code */
            resendOtp: () => t('app.widgets.emailConfirmation.viewer.resendOtp'), /* Resend Code */
            confirm: () => t('app.widgets.emailConfirmation.viewer.confirm'), /* Confirm Email */
            didntGetEmail: () => t('app.widgets.emailConfirmation.viewer.didntGetEmail'), /* Didn't get the email? Check your spam. */
            codeSent: (data: Record<'30', unknown>) => t('app.widgets.emailConfirmation.viewer.codeSent', data), /* Code was sent. You can resend in {30} */
          },
          tooltip: () => t('app.widgets.emailConfirmation.tooltip'), /* This page lets site members confirm their email address when they sign up to your site. */
          states: {
            confirmEmail: () => t('app.widgets.emailConfirmation.states.confirmEmail'), /* Enter code */
            resendTimer: () => t('app.widgets.emailConfirmation.states.resendTimer'), /* Resend timer */
            error: () => t('app.widgets.emailConfirmation.states.error'), /* Error messages */
            warning: () => t('app.widgets.emailConfirmation.states.warning'), /* Throttle notification */
          },
          elements: {
            label: {
              title: () => t('app.widgets.emailConfirmation.elements.label.title'), /* Title */
              subtitle: () => t('app.widgets.emailConfirmation.elements.label.subtitle'), /* Subtitle */
              emailInput: () => t('app.widgets.emailConfirmation.elements.label.emailInput'), /* Email input */
              button: () => t('app.widgets.emailConfirmation.elements.label.button'), /* Submit button */
              didntGetEmail: () => t('app.widgets.emailConfirmation.elements.label.didntGetEmail'), /* Check spam message */
              resendLink: () => t('app.widgets.emailConfirmation.elements.label.resendLink'), /* Resend link */
              resendText: () => t('app.widgets.emailConfirmation.elements.label.resendText'), /* Check spam text */
              serverErrorMessage: () => t('app.widgets.emailConfirmation.elements.label.serverErrorMessage'), /* Server error message */
            },
          },
        },
        expiredToken: {
          design: {
            tabs: {
              title: () => t('app.widgets.expiredToken.design.tabs.title'), /* Title */
              subtitle: () => t('app.widgets.expiredToken.design.tabs.subtitle'), /* Subtitle */
              mainButtons: () => t('app.widgets.expiredToken.design.tabs.mainButtons'), /* Primary button */
              linkButton: () => t('app.widgets.expiredToken.design.tabs.linkButton'), /* Link button */
              background: () => t('app.widgets.expiredToken.design.tabs.background'), /* Background */
            },
            title: () => t('app.widgets.expiredToken.design.title'), /* Expired Reset Link Design */
          },
          pageInfo: {
            title: () => t('app.widgets.expiredToken.pageInfo.title'), /* What’s an Expired Reset Link page? */
            description: () => t('app.widgets.expiredToken.pageInfo.description'), /* <richText>It lets site members know that the link they received to reset their password is expired, and lets them request a new link.</richText> */
          },
          viewer: {
            title: () => t('app.widgets.expiredToken.viewer.title'), /* This link has expired */
            subtitle: () => t('app.widgets.expiredToken.viewer.subtitle'), /* To get a new reset link, go through the reset password process again. */
            goHomeButton: () => t('app.widgets.expiredToken.viewer.goHomeButton'), /* Go to Site Home */
            resetPassword: () => t('app.widgets.expiredToken.viewer.resetPassword'), /* Reset Password */
          },
          tooltip: () => t('app.widgets.expiredToken.tooltip'), /* This page lets site members know that the link they received to reset their password is expired. */
          elements: {
            label: {
              title: () => t('app.widgets.expiredToken.elements.label.title'), /* Title */
              subtitle: () => t('app.widgets.expiredToken.elements.label.subtitle'), /* Subtitle */
              button: () => t('app.widgets.expiredToken.elements.label.button'), /* Submit button */
              secondaryButton: () => t('app.widgets.expiredToken.elements.label.secondaryButton'), /* Secondary button */
            },
          },
        },
        pendingApproval: {
          design: {
            tabs: {
              title: () => t('app.widgets.pendingApproval.design.tabs.title'), /* Title */
              subtitle: () => t('app.widgets.pendingApproval.design.tabs.subtitle'), /* Subtitle */
              description: () => t('app.widgets.pendingApproval.design.tabs.description'), /* Description */
              mainButtons: () => t('app.widgets.pendingApproval.design.tabs.mainButtons'), /* Primary button */
              closeButton: () => t('app.widgets.pendingApproval.design.tabs.closeButton'), /* Close button */
              background: () => t('app.widgets.pendingApproval.design.tabs.background'), /* Background */
            },
            title: () => t('app.widgets.pendingApproval.design.title'), /* Pending Approval Design */
          },
          viewer: {
            title: () => t('app.widgets.pendingApproval.viewer.title'), /* Your signup request was sent and is pending approval */
            subtitle: (data: Record<'email', unknown>) => t('app.widgets.pendingApproval.viewer.subtitle', data), /* You’ll be notified of your approval via the email you used to sign up:
            {email} */
            description: () => t('app.widgets.pendingApproval.viewer.description'), /* Once approved, you’ll be able to log in as a site member. */
            primaryButton: () => t('app.widgets.pendingApproval.viewer.primaryButton'), /* Got It */
          },
          pageInfo: {
            title: () => t('app.widgets.pendingApproval.pageInfo.title'), /* What’s a Pending Approval page? */
            description: () => t('app.widgets.pendingApproval.pageInfo.description'), /* A "Pending Approval" page is shown to people who sign up to your site, letting them know that their member request is pending approval. */
            howTo: {
              title: () => t('app.widgets.pendingApproval.pageInfo.howTo.title'), /* How can I choose who can be a site member? */
              description: () => t('app.widgets.pendingApproval.pageInfo.howTo.description'), /* On the "Signup & Login Security" page, you can choose to manually approve member requests, or allow everyone who signs up to join. */
            },
            link: () => t('app.widgets.pendingApproval.pageInfo.link'), /* Signup & Login Security */
          },
          tooltip: () => t('app.widgets.pendingApproval.tooltip'), /* This page lets site members know that their signup request is pending approval. */
          elements: {
            label: {
              title: () => t('app.widgets.pendingApproval.elements.label.title'), /* Title */
              subtitle: () => t('app.widgets.pendingApproval.elements.label.subtitle'), /* Subtitle */
              email: () => t('app.widgets.pendingApproval.elements.label.email'), /* Email */
              emailTooltip: () => t('app.widgets.pendingApproval.elements.label.emailTooltip'), /* This shows the email that was used to sign up. */
              description: () => t('app.widgets.pendingApproval.elements.label.description'), /* Description */
              button: () => t('app.widgets.pendingApproval.elements.label.button'), /* Submit button */
              closeButton: () => t('app.widgets.pendingApproval.elements.label.closeButton'), /* Close button */
            },
          },
        },
        accessRestricted: {
          design: {
            tabs: {
              title: () => t('app.widgets.accessRestricted.design.tabs.title'), /* Title */
              subtitle: () => t('app.widgets.accessRestricted.design.tabs.subtitle'), /* Subtitle */
              mainButtons: () => t('app.widgets.accessRestricted.design.tabs.mainButtons'), /* Primary button */
              loginButton: () => t('app.widgets.accessRestricted.design.tabs.loginButton'), /* Log in button */
              background: () => t('app.widgets.accessRestricted.design.tabs.background'), /* Background */
            },
            title: () => t('app.widgets.accessRestricted.design.title'), /* Access Restricted Design */
          },
          viewer: {
            title: () => t('app.widgets.accessRestricted.viewer.title'), /* Access to this page is restricted */
            subtitle: () => t('app.widgets.accessRestricted.viewer.subtitle'), /* The content of this page is restricted to site members only. Contact the site owner for more info. */
            goHomeButton: () => t('app.widgets.accessRestricted.viewer.goHomeButton'), /* Back to Site Home */
            switchAccount: () => t('app.widgets.accessRestricted.viewer.switchAccount'), /* Log in with a different email */
          },
          pageInfo: {
            title: () => t('app.widgets.accessRestricted.pageInfo.title'), /* What’s an Access Restricted page? */
            description: () => t('app.widgets.accessRestricted.pageInfo.description'), /* <richText><p>It shows when site members can’t access a part of the site that’s restricted to certain member roles.</p><p>Go to the “Manage Member Access” page to assign roles and set member pages. <textButton>Learn more</textButton></p></richText> */
            learnMore: () => t('app.widgets.accessRestricted.pageInfo.learnMore'), /* Learn more about member pages */
            link: () => t('app.widgets.accessRestricted.pageInfo.link'), /* Manage Member Access */
          },
          tooltip: () => t('app.widgets.accessRestricted.tooltip'), /* This page lets site members know that they don’t have access to this page. */
          elements: {
            label: {
              title: () => t('app.widgets.accessRestricted.elements.label.title'), /* Title */
              subtitle: () => t('app.widgets.accessRestricted.elements.label.subtitle'), /* Subtitle */
              button: () => t('app.widgets.accessRestricted.elements.label.button'), /* Back to home button */
              switchAccount: () => t('app.widgets.accessRestricted.elements.label.switchAccount'), /* Log in link */
            },
          },
        },
        gfpp: {
          managePagePermissions: () => t('app.widgets.gfpp.managePagePermissions'), /* Manage Member Access */
          manageSignupSettings: () => t('app.widgets.gfpp.manageSignupSettings'), /* Signup & Login Settings */
          changeDesign: () => t('app.widgets.gfpp.changeDesign'), /* Change Design */
        },
        displayNames: {
          adminApproval: () => t('app.widgets.displayNames.adminApproval'), /* Pending Approval */
          emailConfirmation: () => t('app.widgets.displayNames.emailConfirmation'), /* Email Confirmation */
          forgotPassword: () => t('app.widgets.displayNames.forgotPassword'), /* Forgot Password */
          resetPassword: () => t('app.widgets.displayNames.resetPassword'), /* Create New Password */
          expiredToken: () => t('app.widgets.displayNames.expiredToken'), /* Expired Reset Link */
          accessRestricted: () => t('app.widgets.displayNames.accessRestricted'), /* Access Restricted */
        },
        errorMessage: {
          design: {
            title: () => t('app.widgets.errorMessage.design.title'), /* Error Message Design */
            text: () => t('app.widgets.errorMessage.design.text'), /* Error message */
            icon: () => t('app.widgets.errorMessage.design.icon'), /* Icon */
          },
        },
        adminApproval: {
          viewer: {
            description: () => t('app.widgets.adminApproval.viewer.description'), /* Once approved, you’ll be able to log in as a site member. */
          },
        },
      },
      components: {
        accessRestricted: {
          displayNames: {
            switchAccount: () => t('app.components.accessRestricted.displayNames.switchAccount'), /* Log in link */
            goHomeButton: () => t('app.components.accessRestricted.displayNames.goHomeButton'), /* Back to home button */
            title: () => t('app.components.accessRestricted.displayNames.title'), /* Title */
            subtitle: () => t('app.components.accessRestricted.displayNames.subtitle'), /* Subtitle */
          },
        },
        expiredToken: {
          displayNames: {
            title: () => t('app.components.expiredToken.displayNames.title'), /* Title */
            subtitle: () => t('app.components.expiredToken.displayNames.subtitle'), /* Subtitle */
            loginButton: () => t('app.components.expiredToken.displayNames.loginButton'), /* Reset password button */
            goHomeButton: () => t('app.components.expiredToken.displayNames.goHomeButton'), /* Back to home button */
          },
        },
        pendingApproval: {
          displayNames: {
            title: () => t('app.components.pendingApproval.displayNames.title'), /* Title */
            subtitle: () => t('app.components.pendingApproval.displayNames.subtitle'), /* Subtitle */
            description: () => t('app.components.pendingApproval.displayNames.description'), /* Description */
            primaryButton: () => t('app.components.pendingApproval.displayNames.primaryButton'), /* Primary button */
            closeButton: () => t('app.components.pendingApproval.displayNames.closeButton'), /* Close button */
          },
        },
        emailConfirmation: {
          displayNames: {
            title: () => t('app.components.emailConfirmation.displayNames.title'), /* Title */
            subtitle: () => t('app.components.emailConfirmation.displayNames.subtitle'), /* Subtitle */
            email: () => t('app.components.emailConfirmation.displayNames.email'), /* User email */
            inputErrorMessage: () => t('app.components.emailConfirmation.displayNames.inputErrorMessage'), /* Input error message */
            errorMessage: () => t('app.components.emailConfirmation.displayNames.errorMessage'), /* Error message */
            warningMessage: () => t('app.components.emailConfirmation.displayNames.warningMessage'), /* Warning message */
            emailInput: () => t('app.components.emailConfirmation.displayNames.emailInput'), /* Code input */
            otpInput: () => t('app.components.emailConfirmation.displayNames.otpInput'), /* Code input */
            resendLink: () => t('app.components.emailConfirmation.displayNames.resendLink'), /* Resend code button */
            didntGetEmail: () => t('app.components.emailConfirmation.displayNames.didntGetEmail'), /* Didn't get the email */
            submitButton: () => t('app.components.emailConfirmation.displayNames.submitButton'), /* Submit button */
            resendTimer: () => t('app.components.emailConfirmation.displayNames.resendTimer'), /* Resend timer */
            closeButton: () => t('app.components.emailConfirmation.displayNames.closeButton'), /* Close button */
          },
        },
        forgotPassword: {
          displayNames: {
            title: () => t('app.components.forgotPassword.displayNames.title'), /* Title */
            subtitle: () => t('app.components.forgotPassword.displayNames.subtitle'), /* Subtitle */
            description: () => t('app.components.forgotPassword.displayNames.description'), /* Description */
            emailInput: () => t('app.components.forgotPassword.displayNames.emailInput'), /* Email input */
            errorMessages: () => t('app.components.forgotPassword.displayNames.errorMessages'), /* Error messages */
            primaryButton: () => t('app.components.forgotPassword.displayNames.primaryButton'), /* Send link button */
            closeButton: () => t('app.components.forgotPassword.displayNames.closeButton'), /* Close button */
            linkSent: {
              title: () => t('app.components.forgotPassword.displayNames.linkSent.title'), /* Title */
              subtitle: () => t('app.components.forgotPassword.displayNames.linkSent.subtitle'), /* Subtitle */
              checkSpam: () => t('app.components.forgotPassword.displayNames.linkSent.checkSpam'), /* Check spam text */
              gotIt: () => t('app.components.forgotPassword.displayNames.linkSent.gotIt'), /* Close lightbox button */
            },
          },
        },
        resetPassword: {
          displayNames: {
            title: () => t('app.components.resetPassword.displayNames.title'), /* Title */
            subtitle: () => t('app.components.resetPassword.displayNames.subtitle'), /* Subtitle */
            createPassword: () => t('app.components.resetPassword.displayNames.createPassword'), /* Create password */
            passwordInput: () => t('app.components.resetPassword.displayNames.passwordInput'), /* Password input */
            confirmPasswordInput: () => t('app.components.resetPassword.displayNames.confirmPasswordInput'), /* Confirm password input */
            success: {
              title: () => t('app.components.resetPassword.displayNames.success.title'), /* Title */
              subtitle: () => t('app.components.resetPassword.displayNames.success.subtitle'), /* Subtitle */
              sucondaryButton: () => t('app.components.resetPassword.displayNames.success.sucondaryButton'), /* Secondary button */
            },
            primaryButton: () => t('app.components.resetPassword.displayNames.primaryButton'), /* Primary button */
          },
        },
      },
      pages: {
        pendingApproval: {
          title: () => t('app.pages.pendingApproval.title'), /* Pending Approval (Lightbox) */
        },
        emailConfirmation: {
          title: () => t('app.pages.emailConfirmation.title'), /* Email Confirmation (Lightbox) */
        },
        forgotPassword: {
          title: () => t('app.pages.forgotPassword.title'), /* Forgot Password (Lightbox) */
        },
        resetPassword: {
          title: () => t('app.pages.resetPassword.title'), /* Create New Password */
        },
        expiredToken: {
          title: () => t('app.pages.expiredToken.title'), /* Expired Reset Link */
        },
        accessRestricted: {
          title: () => t('app.pages.accessRestricted.title'), /* Access Restricted */
        },
      },
      actions: {
        manageMemberAccess: () => t('app.actions.manageMemberAccess'), /* Manage Member Access */
      },
      server: {
        error: {
          placeholder: () => t('app.server.error.placeholder'), /* If an error occurs, a message will be shown here. */
          general: () => t('app.server.error.general'), /* We're having an issue on our end, refresh and try again. */
          tooManyAttempts: () => t('app.server.error.tooManyAttempts'), /* We sent you too many emails. Wait a bit before clicking resend again. */
          emailConfirmation: {
            invalidOtp: () => t('app.server.error.emailConfirmation.invalidOtp'), /* That code isn't correct, try again. */
            codeNotFound: () => t('app.server.error.emailConfirmation.codeNotFound'), /* That code doesn't exist. Try again. */
            tooManyAttempts: () => t('app.server.error.emailConfirmation.tooManyAttempts'), /* We sent you too many emails. You'll be able to try again tomorrow. */
          },
          invalidPassword: () => t('app.server.error.invalidPassword'), /* That password isn't correct. Enter a different password and try again. */
          invalidToken: () => t('app.server.error.invalidToken'), /* That token is invalid. Refresh and try again. */
          forgotPassword: {
            invalidEmail: () => t('app.server.error.forgotPassword.invalidEmail'), /* That email is invalid, check your email and try again. */
            userNotFound: () => t('app.server.error.forgotPassword.userNotFound'), /* An account with that email doesn't exist. */
          },
          resetPassword: {
            userNotAllowed: () => t('app.server.error.resetPassword.userNotAllowed'), /* Password reset is unavailable. Confirm your email or wait for owner approval. */
            invalidToken: () => t('app.server.error.resetPassword.invalidToken'), /* The link you provided has already been used or has expired. */
          },
        },
      },
      input: {
        errorMessages: {
          invalidEmail: () => t('app.input.errorMessages.invalidEmail'), /* Invalid email address. */
          passwordMismatch: () => t('app.input.errorMessages.passwordMismatch'), /* Passwords do not match. */
          passwordLength: {
            tooShort: (data: Record<'min', unknown>) => t('app.input.errorMessages.passwordLength.tooShort', data), /* Minimum password length is {min} characters. */
            tooLong: (data: Record<'max', unknown>) => t('app.input.errorMessages.passwordLength.tooLong', data), /* Maximum password length is {max} characters. */
          },
          onlyNumbers: () => t('app.input.errorMessages.onlyNumbers'), /* Only numbers are allowed. */
          required: () => t('app.input.errorMessages.required'), /* This field is required. */
          emailNotFound: () => t('app.input.errorMessages.emailNotFound'), /* That email doesn't exist. */
          passwordAscii: () => t('app.input.errorMessages.passwordAscii'), /* Your password must contain at least one letter and one number. */
          invalidOtp: () => t('app.input.errorMessages.invalidOtp'), /* That's not the right code, try again. */
        },
      },
      warning: {
        restoreElementText: () => t('app.warning.restoreElementText'), /* This element can’t be removed because it's important for the basic function of the widget or page. */
        restoreElementLink: () => t('app.warning.restoreElementLink'), /* Restore Element */
      },
      adding: {
        authentication: {
          pages: () => t('app.adding.authentication.pages'), /* Adding Authentication Pages */
        },
      },
      installation: {
        prepering: () => t('app.installation.prepering'), /* Prepering your layout... */
        configuring: () => t('app.installation.configuring'), /* Configuring your components... */
        optimazing: () => t('app.installation.optimazing'), /* optimizing your pages... */
        finishing: () => t('app.installation.finishing'), /* Adding the finishing touches... */
      },
    },
    elements: {
      labels: {
        errorMessage: () => t('elements.labels.errorMessage'), /* Error message */
        errorIcon: () => t('elements.labels.errorIcon'), /* Error icon */
      },
    },
  };
}

export type ILocaleKeys = ReturnType<typeof LocaleKeys>;
