import { closePopup } from '../../utils';
import model from './model';

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { user, window, location, site } =
        flowAPI.controllerConfig.wixCodeApi;
      closePopup({ $w, window });
      const homePage = (
        await site.getSiteStructure({ includePageId: true })
      ).pages.find((page) => page.isHomePage);
      $w('#loginButton')?.onClick?.(() => {
        user.promptLogin({});
      });
      $w('#goHomeButton')?.onClick?.(() => {
        location.navigateTo?.({
          ...homePage,
          type: 'PageLink',
          pageId: homePage?.id,
        });
      });
    },
    exports: {},
  };
});
