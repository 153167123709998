import { COMPONENT_IDS } from '../../constants';
import model from './model';

export default model.createController(({ $bindAll, $widget, flowAPI }) => {
  const getContext = async () => {
    try {
      const context =
        // @ts-expect-error
        await flowAPI.controllerConfig.wixCodeApi.window.lightbox.getViewerContext();
      return context;
    } catch (error) {
      return undefined;
    }
  };

  return {
    pageReady: async ($w) => {
      $widget.fireEvent('widgetLoaded', {});

      const context = await getContext();
      $widget.fireEvent('widgetLoaded', {});

      if (context?.email) {
        const emailElement = $w('#email');
        emailElement.text = emailElement.text.replace(
          '{email@wix.com}',
          context.email,
        );
      }
      $w(COMPONENT_IDS.ADMIN_APPROVAL.PRIMARY_BUTTON)?.onClick?.(async () => {
        // @ts-expect-error
        flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
      });
      $w(COMPONENT_IDS.ADMIN_APPROVAL.CLOSE_BUTTON)?.onClick?.(async () => {
        // @ts-expect-error
        flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
      });
    },
    exports: {},
  };
});
