import { COMPONENT_IDS, STATES } from '../../constants';
import { ServerErrorMessage } from '../../editor-app/editor.app.types';
import { initLocaleKeys } from '../../initLocaleKeys';
import {
  Validators,
  serverErrorsHandler,
  validate,
} from '../../validation/validator';
import model from './model';

export default model.createController(({ $widget, flowAPI, $w }) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const validators = Validators(t);

  const clearWidgetState = () => {
    $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR).delete();
  };

  const setServerError = (error: ServerErrorMessage, input?: any) => {
    if (error.shouldDisplayUnderInput) {
      input.inputType = input.inputType;
      return;
    }

    const serverErrorMessageTextElem = $w(
      COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR,
    ).children?.[0];
    serverErrorMessageTextElem.text = error.errorMessage;
    $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR).restore();
  };

  const setLoadingState = (isLoading: boolean) => {
    if (isLoading) {
      return $w(
        COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON,
      ).disable();
    }
    $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON).enable();
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      void $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
        STATES.FORGOT_PASSWORD.EMAIL,
      );

      $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR).delete();
      const emailInput = $w(
        COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.EMAIL_INPUT,
      );

      emailInput.onKeyPress(async (e) => {
        if (e.key === 'Enter') {
          await $w(
            COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON,
          ).onClick();
        }
      });

      emailInput.onCustomValidation((value, reject) =>
        validate(value, [validators.isEmail], reject),
      );

      $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SUBMIT_BUTTON).onClick(
        async (e) => {
          if (!emailInput.validity.valid) {
            return;
          }
          try {
            setLoadingState(true);
            clearWidgetState();
            // @ts-expect-error
            await flowAPI.controllerConfig.wixCodeApi.members.authentication.sendResetPasswordEmail(
              emailInput.value,
            );
            $w(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.EMAIL).text =
              emailInput.value;
            $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
              STATES.FORGOT_PASSWORD.LINK_SENT,
            );
          } catch (error: any) {
            setServerError(serverErrorsHandler(error, t), emailInput);
          } finally {
            setLoadingState(false);
          }
        },
      );

      $w(COMPONENT_IDS.FORGOT_PASSWORD.LINK_STEP.GOT_IT_BUTTON).onClick(
        async () => {
          // @ts-expect-error
          flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
        },
      );

      $w(COMPONENT_IDS.FORGOT_PASSWORD.CLOSE_BUTTON)?.onClick?.(async () => {
        // @ts-expect-error
        flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
      });
    },

    updateWidgetViewState: (viewStateId) => {
      switch (viewStateId) {
        case STATES.FORGOT_PASSWORD.EMAIL:
          clearWidgetState();
          void $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.FORGOT_PASSWORD.EMAIL,
          );
          break;
        case STATES.FORGOT_PASSWORD.ERROR:
          clearWidgetState();
          $w(COMPONENT_IDS.FORGOT_PASSWORD.EMAIL_STEP.SERVER_ERROR).restore();
          void $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.FORGOT_PASSWORD.EMAIL,
          );
          break;
        case STATES.FORGOT_PASSWORD.LINK_SENT:
          void $w(COMPONENT_IDS.FORGOT_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.FORGOT_PASSWORD.LINK_SENT,
          );
          break;
      }
    },
    exports: {},
  };
});
