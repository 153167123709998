import { COMPONENT_IDS } from '../../constants';
import model from './model';

export default model.createController(({ $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});

      $w(COMPONENT_IDS.ACCESS_RESTRICTEDS.SWITCH_ACCOUNT_BUTTON)?.onClick?.(
        () => {
          flowAPI.controllerConfig.wixCodeApi.user.promptLogin({
            mode: 'login',
          });
        },
      );

      $w(COMPONENT_IDS.ACCESS_RESTRICTEDS.BACK_BUTTON)?.onClick?.(() => {
        flowAPI.controllerConfig.wixCodeApi.location.to?.('/');
      });
    },
    exports: {},
  };
});
