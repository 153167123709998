import { i18n } from '@wix/yoshi-flow-editor/i18n';
import { LocaleKeys } from './locale-keys/LocaleKeys';

export const initLocaleKeys = (i18nInstance: i18n) => {
  return LocaleKeys(i18nInstance.t.bind(i18nInstance));
};

export const initLocaleKeysWithTranslationFn = (fn: any) => {
  return LocaleKeys(fn);
};
