import { ILocaleKeys } from '../locale-keys/LocaleKeys';
import {
  IServerErrorResponse,
  IValidationErrorResponse,
} from '../editor-app/editor.app.types';

export type Validator = (value: string) => string | undefined;
type ErrorType = (IServerErrorResponse & IValidationErrorResponse) | string;

const isValidEmail = (emailToTest: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(emailToTest);
};

const isAscii = (char: string) => char.charCodeAt(0) < 127;
const isAsciiOnlyInput = (value: string) => Array.from(value).every(isAscii);
const isPasswordAsciiOnly = (value: string, t: ILocaleKeys) => {
  if (isAsciiOnlyInput(value)) {
    return null;
  }

  return t.app.input.errorMessages.passwordAscii();
};
const ERROR_CODES_TO_DISPLAY_UNDER_INPUT = ['VERIFICATION_FAILED'];

let inputServerErrror: string | undefined;

export const validate = (value: string, validators: Validator[], reject) => {
  const errorMessage = validators.reduce<string | undefined>(
    (error, validator) => error || validator(value),
    undefined,
  );
  if (errorMessage) {
    reject(errorMessage);
  }
  if (inputServerErrror) {
    reject(inputServerErrror);
    inputServerErrror = undefined;
  }
};

export const Validators = (t: ILocaleKeys) => ({
  minLength: (min: number) => (value: string) =>
    value.length >= min
      ? undefined
      : t.app.input.errorMessages.passwordLength.tooShort({
          min,
        }),
  maxLength: (max: number) => (value: string) =>
    value.length <= max
      ? undefined
      : t.app.input.errorMessages.passwordLength.tooLong({
          max,
        }),
  validatePassword: (value: string) => isPasswordAsciiOnly(value, t),
  isEmail: (value: string) => validateSiteMembersEmail(value, t),
  isNumber: (value: string) =>
    /^[0-9]+$/i.test(value)
      ? undefined
      : t.app.input.errorMessages.onlyNumbers(),
  isRequired: (value: string) =>
    value ? undefined : t.app.input.errorMessages.required(),
  matches: (field) => (value: string) =>
    field.value === value
      ? undefined
      : t.app.input.errorMessages.passwordMismatch(),
});

const extractErrorCode = (error: ErrorType): string | undefined => {
  if (typeof error === 'object') {
    return error?.details?.applicationError?.code;
  }
  return error;
};

export const serverErrorsHandler = (
  error: ErrorType,
  t: ILocaleKeys,
): {
  errorMessage: string;
  shouldDisplayUnderInput: boolean;
} => {
  if (typeof error === 'object' && error?.details?.validationError) {
    return handlePlatformizedError(error, t);
  }
  const errorCode = extractErrorCode(error);
  const errorMessage = figureFallbackErrorMessage(errorCode, t);
  const shouldDisplayUnderInput =
    !!errorCode && ERROR_CODES_TO_DISPLAY_UNDER_INPUT.includes(errorCode);

  if (shouldDisplayUnderInput) {
    inputServerErrror = errorMessage;
  }
  return {
    errorMessage,
    shouldDisplayUnderInput,
  };
};

const handlePlatformizedError = (
  error: any,
  t: ILocaleKeys,
): {
  errorMessage: string;
  shouldDisplayUnderInput: boolean;
} => {
  const details = error.details;
  const validationError = details?.validationError?.fieldViolations[0];
  let errorMessage = '';
  if (validationError?.data?.type === 'EMAIL') {
    errorMessage = t.app.input.errorMessages.invalidEmail();
  }
  if (
    validationError?.field === 'password' &&
    validationError?.ruleName === 'VALUE_TOO_SHORT'
  ) {
    errorMessage = t.app.input.errorMessages.passwordLength.tooShort({
      min: 6,
    });
  }
  if (validationError?.ruleName) {
    return validationError.ruleName;
  }
  errorMessage =
    errorMessage ||
    details?.errorcode ||
    details?.applicationError?.code ||
    details?.errorCode ||
    t.app.server.error.general();
  return {
    errorMessage,
    shouldDisplayUnderInput: true,
  };
};

export const validateSiteMembersEmail = (
  value: string,
  t: ILocaleKeys,
): string | undefined => {
  if (value.length === 0) {
    return t.app.input.errorMessages.required();
  }
  if (!isValidEmail(value)) {
    return t.app.input.errorMessages.invalidEmail();
  }
};

export const figureFallbackErrorMessage = (
  failureReason: string | number | undefined,
  t: ILocaleKeys,
) => {
  switch (failureReason) {
    case 'NOT_FOUND':
      return t.app.server.error.emailConfirmation.codeNotFound();
    case 'BAD_CODE':
    case 'VERIFICATION_FAILED':
      return t.app.server.error.emailConfirmation.invalidOtp();
    case '-19901':
      return t.app.server.error.tooManyAttempts();
    case 'INVALID_PASSWORD':
      return t.app.server.error.invalidPassword();
    case 'INVALID_TOKEN':
      return t.app.server.error.invalidToken();
    case 'INVALID_EMAIL':
      return t.app.server.error.forgotPassword.invalidEmail();
    case 'USER_NOT_FOUND':
    case '-19999':
      return t.app.server.error.forgotPassword.userNotFound();
    case 'IDENTITY_NOT_ALLOWED_TO_RECOVER':
      return t.app.server.error.resetPassword.userNotAllowed();
    case 'RECOVERY_TOKEN_DATA_NOT_FOUND':
      return t.app.server.error.resetPassword.invalidToken();
    default:
      return t.app.server.error.general();
  }
};

export const validateInputs = (inputs: any[]) => {
  inputs.forEach((input) => {
    if (!input.validity.valid) {
      input.inputType = input.inputType;
    }
  });
};
