import { STATES } from '../../constants';
import { ServerErrorMessage } from '../../editor-app/editor.app.types';
import { initLocaleKeys } from '../../initLocaleKeys';
import {
  Validators,
  serverErrorsHandler,
  validate,
} from '../../validation/validator';
import model from './model';

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const validators = Validators(t);

  const clearWidgetState = () => {
    $w('#serverErrorMessage').delete();
    $w('#warning').delete();
    $w('#resendTimer').delete();
    $w('#emailConfirmationResendTextLink').restore();
  };
  const handleResendTimer = async (cb) => {
    return new Promise<void>((resolve) => {
      let timeLeft = 30;
      cb(timeLeft);
      const countdown = setInterval(() => {
        timeLeft--;
        const timeLeftStr = timeLeft < 10 ? `0${timeLeft}` : `${timeLeft}`;
        cb(timeLeftStr);
        if (timeLeft < 1) {
          clearInterval(countdown);
          timeLeft = 30;
          resolve();
        }
      }, 1000);
    });
  };
  const setServerError = (error: ServerErrorMessage, input?: any) => {
    if (error.shouldDisplayUnderInput) {
      input.inputType = input.inputType;
      return;
    }
    const serverErrorMessageTextElem = $w('#serverErrorMessage').children?.[0];
    serverErrorMessageTextElem.text = error.errorMessage;
    $w('#serverErrorMessage').restore();
  };

  const setLoadingState = (isLoading: boolean) => {
    if (isLoading) {
      return $w('#submitButton').disable();
    }
    $w('#submitButton').enable();
  };

  const getContext = async () => {
    try {
      const context =
        // @ts-expect-error
        await flowAPI.controllerConfig.wixCodeApi.window.lightbox.getViewerContext();
      return context;
    } catch (error) {
      return undefined;
    }
  };
  return {
    pageReady: async () => {
      // @ts-expect-error
      flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
      clearWidgetState();

      const { email } = await getContext();
      const template = $w('#resendTimer').children[1].html;
      $widget.fireEvent('widgetLoaded', {});

      if (email) {
        try {
          const emailConfirmationSubtitleElement = $w('#email');
          emailConfirmationSubtitleElement.text = email;
        } catch (error) {
          console.error('Failed to set email confirmation subtitle', error);
        }
      }

      const otpInput = $w('#otpInput');
      otpInput.onCustomValidation((value, reject) =>
        validate(value, [validators.minLength(6)], reject),
      );

      otpInput.onKeyPress(async (e) => {
        if (e.key === 'Enter') {
          await $w('#submitButton').onClick();
        }
      });

      $w('#submitButton').onClick(async (e) => {
        if (!otpInput.validity.valid) {
          return;
        }
        try {
          setLoadingState(true);
          clearWidgetState();
          // @ts-expect-error
          await flowAPI.controllerConfig.wixCodeApi.members.authentication.verifyEmail(
            otpInput.value,
          );
          // @ts-expect-error
          flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
        } catch (error: any) {
          setServerError(serverErrorsHandler(error, t), otpInput);
        } finally {
          setLoadingState(false);
        }
      });

      // handle resend otp
      $w('#emailConfirmationResendTextLink').onClick(async (e) => {
        try {
          // @ts-expect-error
          await flowAPI.controllerConfig.wixCodeApi.members.authentication.resendVerificationCodeEmail();
          $w('#resendTimer').restore();
          $w('#emailConfirmationResendTextLink').delete();
          $w('#serverErrorMessage').delete();

          await handleResendTimer((timeLeft) => {
            $w('#resendTimer').children[1].html = template.replace(
              '{30}',
              `${timeLeft}`,
            );
          });
          $w('#resendTimer').delete();
          $w('#emailConfirmationResendTextLink').restore();
        } catch (error: any) {
          setServerError(serverErrorsHandler(error, t));
        }
      });
      $w('#emailConfirmationCloseBtn').onClick(() => {
        // @ts-expect-error
        flowAPI.controllerConfig.wixCodeApi.window.lightbox.close();
        flowAPI.controllerConfig.wixCodeApi.user.promptLogin({
          mode: 'signup',
        });
      });
    },
    updateWidgetViewState: (viewStateId) => {
      switch (viewStateId) {
        case STATES.EMAIL_CONFIRMATION.ERROR:
          clearWidgetState();
          $w('#serverErrorMessage').restore();
          break;
        case STATES.EMAIL_CONFIRMATION.WARNING:
          clearWidgetState();
          $w('#warning').restore();
          break;
        case STATES.EMAIL_CONFIRMATION.CODE_RESEND_TIME:
          clearWidgetState();
          $w('#emailConfirmationResendTextLink').delete();
          $w('#resendTimer').restore();
          break;
        default:
          clearWidgetState();
          break;
      }
    },

    exports: {},
  };
});
