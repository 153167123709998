import { COMPONENT_IDS, STATES } from '../../constants';
import { ServerErrorMessage } from '../../editor-app/editor.app.types';
import { initLocaleKeys } from '../../initLocaleKeys';
import {
  Validators,
  serverErrorsHandler,
  validate,
  validateInputs,
} from '../../validation/validator';
import model from './model';

export default model.createController(({ $widget, flowAPI, $w }) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const validators = Validators(t);
  const clearWidgetState = () => {
    $w(
      COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR,
    )?.delete();
  };

  $w(COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR)?.delete();

  const setServerError = (error: ServerErrorMessage, input?: any) => {
    if (error.shouldDisplayUnderInput) {
      input.inputType = input.inputType;
      return;
    }
    const errorElement = $w(
      COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR,
    ).children[0];
    errorElement.text = error.errorMessage;
    $w(
      COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR,
    ).restore();
  };

  const setLoadingState = (isLoading: boolean) => {
    if (isLoading) {
      return $w('#resetPasswordCTA').disable();
    }
    $w('#resetPasswordCTA').enable();
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});

      const passwordInput = $w(
        COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.PASSWORD_INPUT,
      );
      passwordInput.onCustomValidation((value, reject) =>
        validate(value, [validators.minLength(6)], reject),
      );

      passwordInput.onKeyPress(async (e) => {
        if (e.key === 'Enter') {
          await $w(
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SUBMIT_BUTTON,
          ).onClick();
        }
      });

      const verifyPasswordInput = $w(
        COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP
          .CONFIRM_PASSWORD_INPUT,
      );

      verifyPasswordInput.onCustomValidation((value, reject) =>
        validate(
          value,
          [validators.matches(passwordInput), validators.isRequired],
          reject,
        ),
      );

      verifyPasswordInput.onKeyPress(async (e) => {
        if (e.key === 'Enter') {
          await $w(
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SUBMIT_BUTTON,
          ).onClick();
        }
      });

      $w('#resetPasswordCTA').onClick(async (e) => {
        if (
          !passwordInput.validity.valid ||
          !verifyPasswordInput.validity.valid
        ) {
          validateInputs([passwordInput, verifyPasswordInput]);
          return;
        }
        const newPassword = passwordInput.value;
        try {
          setLoadingState(true);
          clearWidgetState();
          const { resetPasswordToken } =
            flowAPI.controllerConfig.wixCodeApi.window.getAppPageData<any>();
          // @ts-expect-error
          await flowAPI.controllerConfig.wixCodeApi.members.authentication.changePassword(
            newPassword,
            resetPasswordToken,
          );
          $w('#createNewPasswordState').changeState('success');
        } catch (error: any) {
          setServerError(serverErrorsHandler(error, t), passwordInput);
        } finally {
          setLoadingState(false);
        }
      });
      $w('#loginBtn').onClick(() => {
        flowAPI.controllerConfig.wixCodeApi.user.promptLogin({});
      });

      const homePage = (
        await flowAPI.controllerConfig.wixCodeApi.site.getSiteStructure({
          includePageId: true,
        })
      ).pages.find((page) => page.isHomePage);
      $w('#homeBtn')?.onClick?.(() => {
        flowAPI.controllerConfig.wixCodeApi.location.navigateTo?.({
          ...homePage,
          type: 'PageLink',
          pageId: homePage?.id,
        });
      });
      $w('#errorBox')?.delete?.();
    },
    updateWidgetViewState: (viewStateId) => {
      switch (viewStateId) {
        case STATES.RESET_PASSWORD.ERROR:
          clearWidgetState();
          $w(
            COMPONENT_IDS.RESET_PASSWORD.CREATE_PASSWORD_STEP.SERVER_ERROR,
          ).restore();
          void $w(COMPONENT_IDS.RESET_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.RESET_PASSWORD.CREATE_PASSWORD,
          );
          break;
        case STATES.RESET_PASSWORD.SUCCESS:
          clearWidgetState();
          void $w(COMPONENT_IDS.RESET_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.RESET_PASSWORD.SUCCESS,
          );
          break;
        case STATES.RESET_PASSWORD.CREATE_PASSWORD:
          clearWidgetState();
          void $w(COMPONENT_IDS.RESET_PASSWORD.MULTI_STATE_BOX).changeState(
            STATES.RESET_PASSWORD.CREATE_PASSWORD,
          );
          break;
      }
    },
    exports: {},
  };
});
